import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { SocialShare } from '../../data/data';
import { FooterLinks } from '../../data/data';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="footer-style-2 pb--20">
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-5">
              <div className="inner">
                <div className="logo mb_sm--20">
                  <a href="/">
                    <img src="/assets/images/logo/noctil-logo.png" alt="Noctil" />
                  </a>
                  <p>Metadata Management Made Easy</p>
                </div>
                <div className="footer-social-icons">
                  {SocialShare.map((val, i) => (
                    <a
                      key={i}
                      href={`${val.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{val.Social}</span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="inner footerlinks">
                <ul className="footer-link liststyle">
                  {FooterLinks.map((val, i) => (
                    <li key={i}>
                      <a href={`${val.link}`}>{val.Label}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="inner">
                <div className="contact">
                  <span className="title">USA</span>
                  <p>
                    1390 Chain Bridge Road,<br></br>
                    STE #A274, McLean, VA 22101<br></br>
                  </p>
                  <span className="title">India</span>
                  <p>
                    #14, 4th Floor , Heavenly Plaza,
                    <br />
                    Kochi, Kerala, India 682021
                    <br />
                  </p>
                  <div className="flex-row-gap--10 mt--20">
                    <a href="mailto:sales@noctil.com">
                      <FaEnvelope size={22} className="twitter-icon" />
                    </a>
                    <a href="tel:+17039923990">
                      <FaPhone size={22} className="linkedin-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="copyright-text">
                <p>&copy; Copyright noctil {getCurrentYear()} | All Rights Reserved</p>
              </div>
            </div>
            <CookieConsent
              location="bottom"
              buttonText="I accept"
              cookieName="noctilCookie"
              style={{ background: '#2B373B' }}
              overlay="true"
              buttonStyle={{ color: '#4e503b', fontSize: '16px' }}
              expires={150}
            >
              We use cookies to offer you a better browsing experience, personalize
              content and ads, provide social media features, and analyze our traffic. You
              consent to our cookies if you continue using this website. To learn more
              about how we process your personal data, read our{' '}
              <a href="privacy-policy">Privacy Policy</a>
            </CookieConsent>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

function getCurrentYear() {
  const currentYear = new Date().getFullYear();
  return currentYear;
}
